<template>
  <footer>
    <img src="../images/servace-logo-footer.png" />
    <div class="contact-info">
      <h3 style="color: #65b22e">Phone us</h3>
      <span class="alignment-styles">Tel: 0344 822 3227</span>
      <h3 style="color: #00b2f0">Email us</h3>
      <span class="alignment-styles">info@servace.co.uk</span>
    </div>
    <div class="address">
      <h3 style="color: #ffed00">Our Address</h3>
      <div style="width: 150px">
        <span class="alignment-styles">
          Servace <br />10 Chapell Lane South Wynyard Stockton-on-Tees TS22 5FG
        </span>
      </div>
    </div>
    <p class="copy">&copy; 2024 Servace Limited</p>
  </footer>
</template>

<style scoped>
footer {
  width: 100%;
  height: 18rem;
  background-color: #004f59;
}

img {
  width: 22rem;
  display: flex;
  position: relative;
  top: 3.5rem;
  left: 1.5rem;
}

h3 {
  gap: 4px;
  font-size: 1.5rem;
  line-height: 2.25rem;
}

span {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: white;
}

.address {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  bottom: 14.5rem;
  left: 50%;
}

.contact-info {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 4.3rem;
  left: 80%;
}

.copy {
  text-align: right;
  position: relative;
  margin-right: 5.6rem;
  margin-top: -16rem;
  color: white;
}

.alignment-styles {
  position: relative;
  bottom: 1rem;
  left: 0;
}
</style>
